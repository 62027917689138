import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NavService } from "../../../../services/nav.service";
import { SubjectService } from "../../../../services/subject/subject.service";

@Component({
  selector: "app-languages",
  templateUrl: "./languages.component.html",
  styleUrls: ["./languages.component.scss"],
})
export class LanguagesComponent implements OnInit {
  public language: boolean = false;

  public languages: any[] = [
    {
      language: "English",
      code: "en",
      type: "IN",
      icon: "us",
    },
    {
      language: "Gujarati",
      code: "gu",
      type: "IN",
      icon: "in",
    },
    // {
    //   language: "Gujarati",
    //   code: "in-gj",
    //   type: "IN",
    //   icon: "in",
    // },
    // {
    //   language: 'Español',
    //   code: 'es',
    //   icon: 'es'
    // },
    // {
    //   language: 'Français',
    //   code: 'fr',
    //   icon: 'fr'
    // },
    // {
    //   language: 'Português',
    //   code: 'pt',
    //   type: 'BR',
    //   icon: 'pt'
    // }
  ];

  public selectedLanguage: any = {
    language: "English",
    code: "en",
    type: "US",
    icon: "us",
  };

  constructor(
    public navServices: NavService,
    private translate: TranslateService,
    public subjectService:SubjectService  
  ) {}
  //
  ngOnInit() {
    const lang = localStorage.getItem("lang");
    if (lang) {
      this.translate.use(lang);
      this.selectedLanguage = this.languages.find((item) => item.code === lang);
      // this.subjectService.languageCodeSubject.next(lang);
    }
  }
  
  changeLanguage(lang) {
    this.translate.use(lang.code);
    localStorage.setItem("lang", lang.code);
    this.selectedLanguage = lang;
    this.subjectService.languageCodeSubject.next(lang.code);
  }
}
