import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { NavService } from "src/app/shared/services/nav.service";
import { PermissionService } from "src/app/shared/services/permission.service";
import { SharedService } from "src/app/shared/services/shared.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: string = '';
  isLoading: boolean = false
  isSubmitted: boolean = false
  public today: number = Date.now();

  constructor(private fb: FormBuilder, public router: Router, private authService: AuthService, private sharedService: SharedService, private permissionService: PermissionService, private translate: TranslateService) {
    this.loginForm = this.fb.group({
      mobile: ["", Validators.required], //"9999999999", 
      password: ["", Validators.required], //"123456789", 
    });
    // this.translate.use('in-gj')
  }

  ngOnInit() { }

  login() {
    this.isSubmitted = true
    if (this.loginForm.invalid) {
      this.isLoading = false
      return;
    }
    this.isLoading = true
    const { mobile, password } = this.loginForm.value;
    this.authService.login({ mobileNo: mobile, password: password }).subscribe({
      next: (res) => {
        if (res["success"] == true) {
          const userData = res["data"];
          this.sharedService.userData = userData;
          this.permissionService.permission = userData.permission
          // console.log(this.permissionService.permission)
          const permission = btoa(JSON.stringify(userData.permission))
          sessionStorage.setItem("data1", permission);
          sessionStorage.setItem("user", JSON.stringify(userData));
          JSON.parse(sessionStorage.getItem("user")!);
          // this.navService.createSideMenu();
          this.router.navigate(["/dashboard"]);
        } else {
          sessionStorage.setItem("user", "null");
          JSON.parse(sessionStorage.getItem("user")!);
          this.authService.signOut();
        }
        this.isLoading = false
      },
      error: (err) => {
        this.errorMessage = err;
        this.isLoading = false
      }, complete: () => {
      }
    });
  }

  showPassword() {
    this.show = !this.show
  }
}
