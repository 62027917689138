import { Component, Input, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-three-dot-dropdown",
  templateUrl: "./three-dot-dropdown.component.html",
  styleUrls: ["./three-dot-dropdown.component.scss"],
})
export class ThreeDotDropdownComponent {
  @ViewChild("statusChangeModal") statusChangeModal: ElementRef;
  currentStatus: boolean;
  selectedItemIndex: number;
  modalReference: any;
  isActive: boolean;
  @Input() Itemslink: [] = [];
  options = [{ link: "edit", option: "Edit Post" }];
  @Input() pageid: number;

  constructor(config: NgbModalConfig, private modalService: NgbModal) {}

  statusChanged(e: Event, index) {
    const element = e.target as HTMLInputElement;
    this.selectedItemIndex = index;
    this.item[index].isActive = element.checked;
    this.modalReference = this.modalService.open(this.statusChangeModal, {
      centered: true,
      size: "",
    });
  }
  updateStatus() {
    console.log(this.item[this.selectedItemIndex]);
    this.modalReference.close();
  }
  updateStatusCanceled() {
    this.item[this.selectedItemIndex].isActive =
      !this.item[this.selectedItemIndex].isActive;
    this.modalReference.close();
  }

  @Input() Items: [] = [];
  checkboxState: boolean = false;
  textStatus: string = "Block";
  updateTextStatus() {
    this.textStatus = this.checkboxState ? "Active" : "Block";
  }
  item = [
    { isActive: true },

    // { option: "option 4" },
  ];
}
