import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { User } from "../model/user.model";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  userData: User;
  constructor(private http: HttpClient, private authservice: AuthService) { }
  public handleError(errorRes: HttpErrorResponse) {
    let errorMessage = "an error occured";
    if (!errorRes.error || !errorRes.status) {
      return throwError(() => errorMessage);
    }
    console.log(errorRes);
    switch (errorRes.status) {
      case 404:
        errorMessage = errorRes.error.message;
        break;
      case 500:
        errorMessage = "Internal Server Error";
        break;
      case 401:
        errorMessage = "Unauthorized";
        localStorage.clear();
        sessionStorage.clear();
        location.reload()
        break;
    }
    return throwError(() => errorMessage);
  }
}
