import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Post } from '../../model/post.model';
import { CommentService } from '../../services/post/comments.service';
import { NotifyService } from '../../services/notify.service';
import { SharedPostService } from '../../services/post/shared-post.service';

@Component({
  selector: 'app-side-comments',
  templateUrl: './side-comments.component.html',
  styleUrls: ['./side-comments.component.scss']
})
export class SideCommentsComponent implements OnInit {
  postLike = true;
  disabled = true;
  viewImage: string = null;
  modalReference: any;
  selectedItemIndex: number;
  commentList: any[] = []
  likesList: any[] = []
  commentCurrentPage: number = 0
  likeCurrentPage: number = 0
  commentTotalPage: number = 0
  likeTotalPage: number = 0
  isLoading: boolean = false
  @Input() displayStatusBadge: boolean = true
  @Input() displayLikeAndComments: Boolean = true
  @ViewChild("statusChangeModal") statusChangeModal: ElementRef;
  @Input() post: Post;
  @Input() postId: number

  constructor(config: NgbModalConfig, private modalService: NgbModal, private commentService: CommentService, private notify: NotifyService, private sharedPostService: SharedPostService) {
    config.size = 'xl';
    config.backdrop = 'static';
    config.centered = true

  }
  ngOnInit(): void {
    this.fetchCommentList();
    this.fetchLikeList();
  }

  statusChanged(e: Event, index) {
    // console.log('in status changed',e)
    const element = e.target as HTMLInputElement;
    this.selectedItemIndex = index;
    this.post.comments[index].isActive = element.checked;
    this.modalReference = this.modalService.open(this.statusChangeModal, {
      centered: true,
      size: 'md'
    });
  }


  openModel(content, img) {
    this.modalReference = this.modalService.open(content)
    this.viewImage = img
  }
  closeModel() {
    this.modalReference.close();
    this.viewImage = null
  }

  getTimeAgo(date) {
    let now = new Date();
    let difference = now.getTime() - new Date(date.split(' ').join('T')).getTime();
    if (difference > 365 * 24 * 3600 * 1000) {
      return `${Math.floor(difference / (365 * 24 * 3600 * 1000))} ${Math.floor(difference / (365 * 24 * 3600 * 1000)) === 1 ? "year" : "years"} ago`;
    } else if (difference > 30 * 24 * 3600 * 1000) {
      return `${Math.floor(difference / (30 * 24 * 3600 * 1000))} ${Math.floor(difference / (30 * 24 * 3600 * 1000)) === 1 ? "month" : "months"} ago`;
    } else if (difference > 7 * 24 * 3600 * 1000) {
      return `${Math.floor(difference / (7 * 24 * 3600 * 1000))} ${Math.floor(difference / (7 * 24 * 3600 * 1000)) === 1 ? "week" : "weeks"} ago`;
    } else if (difference > 24 * 3600 * 1000) {
      return `${Math.floor(difference / (24 * 3600 * 1000))} ${Math.floor(difference / (24 * 3600 * 1000)) === 1 ? "day" : "days"} ago`;
    } else if (difference > 3600 * 1000) {
      return `${Math.floor(difference / (3600 * 1000))} ${Math.floor(difference / (3600 * 1000)) === 1 ? "hour" : "hours"} ago`;
    } else if (difference > 60 * 1000) {
      return `${Math.floor(difference / (60 * 1000))} ${Math.floor(difference / (60 * 1000)) === 1 ? "minute" : "minutes"} ago`;
    } else if (difference > 1000) {
      return `${Math.floor(difference / 1000)} ${Math.floor(difference / 1000) === 1 ? "second" : "seconds"} ago`;
    }
    return "just now";
  }

  fetchCommentList() {
    this.isLoading = true
    this.sharedPostService.getPostCommentList(this.postId, this.commentCurrentPage + 1).subscribe({
      next: (res) => {
        this.commentList = [...this.commentList, ...res['data']['data']];
        this.commentCurrentPage = res['data']['current_page'];
        this.commentTotalPage = res['data']['last_page'];
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      }
    })
  }

  fetchLikeList() {
    this.isLoading = true
    this.sharedPostService.getPostListList(this.postId, this.likeCurrentPage + 1).subscribe({
      next: (res) => {
        this.likesList = [...this.likesList, ...res['data']['data']];
        this.likeCurrentPage = res['data']['current_page'];
        this.likeTotalPage = res['data']['last_page'];
        this.isLoading = false
      },
      error: (error) => {
        this.isLoading = false
        console.log(error);
      }
    })
  }

  updateStatusCanceled() {
    this.post.comments[this.selectedItemIndex].isActive = !this.post.comments[this.selectedItemIndex].isActive;
    // ((this.lstPost.data[this.selectedItemIndex].isActive) == true) ? ($('#' + this.selectedItemIndex + 'status').prop('checked', true)) : ($('#' + this.selectedItemIndex + 'status').prop('checked', false))
    this.modalReference.close();
  }


  updateStatus() {
    // console.log(this.tableList[this.selectedItemIndex]);
    this.modalReference.close();
    const body = {
      postId: this.post.postId,
      commentId: this.post.comments[this.selectedItemIndex].commentId,
      isActive: this.post.comments[this.selectedItemIndex].isActive ? 1 : 0,
    };
    this.commentService.updateCommentStatus(body).subscribe({
      next: (res) => {
        if (res["success"] == true) {
          this.notify.showToast("success", res["message"]);
        }
      },
      error: (err) => {
        this.notify.showToast("error", err);
        this.post.comments[this.selectedItemIndex].isActive = !this.post.comments[this.selectedItemIndex].isActive;
        console.log(err);
      },
    })
    // console.log(body);
  }
}