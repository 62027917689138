import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  @Input() width_title: string = 'col-auto col-md-6';
  @Input() width_btn: string = 'col-auto col-md-6 text-end';
  
  constructor() {
  }

  ngOnInit() {  }

}
