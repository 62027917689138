import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // Check if value is a valid date
    if (!value) return value;
    // Parse the input string into a Date object


    const dateParts = value.split(" ");
    const datePart = dateParts[0].split("-");
    const timePart = dateParts[1].split(":");
    const year = parseInt(datePart[0]);
    const month = parseInt(datePart[1]) - 1; // Months are 0-based in JavaScript
    const day = parseInt(datePart[2]);
    const hours = parseInt(timePart[0]);
    const minutes = parseInt(timePart[1]);
    const seconds = parseInt(timePart[2]);

    const date = new Date(year, month, day, hours, minutes, seconds);
    // const date = new Date(value.toString());

    // Check if the date is valid
    if (isNaN(date.getTime())) return value;

    const datePipe = new DatePipe('en-US');
    // return 'hello';

    // Format the date
    const formattedDate = datePipe.transform(date, 'dd-MM-yyyy HH:mm a');

    return formattedDate;
  }

}
