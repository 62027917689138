import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotifyService {
  constructor(private toast: ToastrService) {}

  showToast(type: string, message: string) {
    switch (type) {
      case "success":
        this.toast.success(message);
        break;

      case "error":
        this.toast.error(message);
        break;
      default:
        this.toast.info(message);
        break;
    }
  }
}
