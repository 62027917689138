<!-- <ng-template #viewModal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{ title }}</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModel()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="model-view-image">
                <img [src]="( viewData.member_detail && viewData.member_detail.profileImageUrl) ? viewData.member_detail.profileImageUrl : '/assets/images/avtar/16.jpg'" alt="" class="" width="100%" height="100%" style="object-fit: contain;">
            </div>
        </div>
    </div>
</ng-template> -->
<div class="card preventive-card mb-2 h-100">
    <div class="card-header pt-0 pb-1 px-2">
        <div class="row">
            <div class="col d-flex mb-2">
                <div class="col-auto" style="z-index: 2;">
                    <div class="profile-box">
                        <img [src]="( viewData.member_detail && viewData.member_detail.profileImageUrl) ? viewData.member_detail.profileImageUrl : '/assets/images/avtar/16.jpg'" 
                            alt="" width="100%" height="100%" style="object-fit: cover;">
                    </div>
                </div>
                <div class="ms-2 col-auto" style="z-index: 2;">
                    <div class="user-name"><strong>{{ (viewData.member_detail && viewData.member_detail.fullName) ? viewData.member_detail.fullName : '-' }}</strong></div>
                    <div class="d-flex"><small>{{ viewData  .feedbackDate | date:'dd-MM-yyyy, hh:mm a' }}</small></div>
                </div>
            </div>
        </div>
    </div>
    <div class=" card-body pt-2 pb-1 px-2">
        <!-- ================ Title / Description Section ================ -->
        <div><strong>Feedback</strong></div>
        <div class="text-secondary">
            <small class="post-text-content">{{ viewData.message }}</small>
        </div>
    </div>
</div>