export class API {
  // !############################################ Base URL ######################################

  // public static baseUrl = "https://apivvo.communityplatform.in/api/v1/";
  public static baseUrl = "https://apibeta.communityplatform.in/api/v1/";

  // !======================================== Module Managment ===========================================

  public static allModuleList = this.baseUrl + "admin/module/list";
  public static availableModuleList =
    this.baseUrl + "admin/module/available_list";
  public static addModule = this.baseUrl + "admin/module/add";

  // !============================================ Auth ==========================================
  public static login = this.baseUrl + "admin/auth/login";
  public static register = this.baseUrl + "";

  // !============================================ Profile ==========================================
  public static profileDetail = this.baseUrl + "admin/user/profile/detail";
  public static profileEdit = this.baseUrl + "admin/user/profile/edit";
  public static changePassword = this.baseUrl + "admin/user/profile/change-password";

  // !============================================ Dashboard ==========================================
  public static dashboardStatics = this.baseUrl + "admin/dashboard/statics";

  // !============================================ Member ==========================================
  public static allMemberList = this.baseUrl + "admin/member/list";
  public static memberDetail = this.baseUrl + "admin/member/detail/";
  public static updateMemberVerifyStatus = this.baseUrl + "admin/member/update-status";
  public static verifyStatusList = this.baseUrl + "common/verification-status/list";

  // !============================================ Post ==========================================

  public static addPost = this.baseUrl + "admin/post/add";
  public static getPost = this.baseUrl + "admin/post/list";
  public static getVillageList = this.baseUrl + "common/village/list";
  public static getPostDetail = this.baseUrl + "admin/post/detail/";
  public static getPostDetailCommentList = this.baseUrl + "admin/post/post-list-comments/";
  public static getPostDetailLikeList = this.baseUrl + "admin/post/post-list-likes/";

  public static updatePostStatus =
    this.baseUrl + "admin/post/update-post-status";
  public static updateActiveStatus =
    this.baseUrl + "admin/post/update-active-status";

  public static getCommunityPostList =
    this.baseUrl + "admin/post/list-community";

  public static notifyPost = this.baseUrl + "admin/post/notification-broadcast";

  public static addEditFamilyMember = this.baseUrl + "admin/post/add-edit-family-member";
  public static addEditContactPerson = this.baseUrl + "admin/post/add-edit-contact-person";
  public static deleteContactPerson = this.baseUrl + "admin/post/delete-contact-person";
  public static deleteFamilyMember = this.baseUrl + "admin/post/delete-family-member";
  public static updateAntimParthnaPost = this.baseUrl + "admin/post/update-antim-prathna-post";
  // !============================================ Patrika ==========================================
  public static patrikaEdit = this.baseUrl + "";
  public static getpatrikaList = this.baseUrl + "admin/post/list-patrika";
  public static deletePatrikaImage = this.baseUrl + "admin/post/patrika-delete-image";
  public static addPatrikaImage = this.baseUrl + "admin/post/patrika-add-image";
  public static updatePatrikaPost = this.baseUrl + "admin/post/update-patrika-post";

  // !============================================ Member Post => Pending Member Post ==========================================
  public static allMemberPostPendingList = this.baseUrl + "";
  public static memberPostPendingDetail = this.baseUrl + "";

  // !============================================ Member Post => Active / Inactive Post ==========================================
  public static allMemberPostActiveInactiveList = this.baseUrl + "";
  public static memberPostActiveInactiveDetail = this.baseUrl + "";

  // !============================================ Member Post => Cancelled Post ==========================================
  public static allMemberPostCancelledList = this.baseUrl + "";
  public static memberPostCancelledDetail = this.baseUrl + "";

  // !============================================ Antim Yatra Post => Add Antim Yatra ==========================================
  // public static addAntimYatraPost = this.baseUrl + "admin/post/add";


  // !============================================ Antim Yatra Post => Pending List ==========================================
  public static allAntimYatraPostPendingList = this.baseUrl + "";
  public static antimYatraPostPendingDetail = this.baseUrl + "";

  // !============================================ Antim Yatra Post => Active / Inactive ==========================================
  public static allAntimYatraPostActiveInactiveList = this.baseUrl + "";
  public static antimYatraPostActiveInactiveDetail = this.baseUrl + "";
  public static antimYatraPostActiveInactiveEdit = this.baseUrl + "";

  // !============================================ Antim Yatra Post => Cancelled ==========================================
  public static allAntimYatraPostCancelledList = this.baseUrl + "";
  public static antimYatraPostCancelledDetail = this.baseUrl + "";

  // !============================================ Prayer Maran Post => Add Prayer ==========================================
  // public static addPrayer = this.baseUrl + "";

  // !============================================ Prayer Maran Post => Pending ==========================================
  public static allPrayerMaranPostPendingList = this.baseUrl + "";
  public static PrayerMaranPostPendingDetail = this.baseUrl + "";

  // !============================================ Prayer Maran Post => Active / Inactive ==========================================
  public static allPrayerMaranPostActiveInactiveList = this.baseUrl + "";
  public static prayerMaranPostActiveInactiveDetail = this.baseUrl + "";
  public static prayerMaranPostActiveInactiveEdit = this.baseUrl + "";

  // !============================================  Prayer Maran Post => Cancelled ==========================================
  public static allPrayerMaranPostCancelledList = this.baseUrl + "";
  public static PrayerMaranPostCancelledDetail = this.baseUrl + "";

  // !============================================   Community Post ==========================================
  public static allCommunityList = this.baseUrl + "";
  public static communityDetail = this.baseUrl + "";
  public static communityEdit = this.baseUrl + "";
  public static addBannerImage = this.baseUrl + "admin/post/add-banner-image";
  public static deleteBannerImage = this.baseUrl + "admin/post/delete-banner-image";
  public static updateCommunityPost = this.baseUrl + "admin/post/update-community-post";
  public static addMoreImage = this.baseUrl + "admin/post/patrika-add-image";
  public static deleteMoreImage = this.baseUrl + "admin/post/patrika-delete-image";
  // public static addCommunity = this.baseUrl + "";
  // !============================================   Comments ==========================================
  public static allCommentsList = this.baseUrl + "admin/post/list-comment";
  public static commentsDetailWithPost = this.baseUrl + "admin/post/detail/[postId]/comment/[commentId]";
  public static updateCommentStatus = this.baseUrl + "admin/post/update-comment-status";

  // !============================================  Feedback Management ==========================================
  public static feedbackList = this.baseUrl + "admin/feedback/list";
  public static feedbackDetail = this.baseUrl + "admin/feedback/detail/";
  
  // !============================================  CMS ==========================================
  
  // !============================================  Social Media ==========================================
  public static getSocialMedia = this.baseUrl + "admin/social-media/list";
  public static updateSocialMediaLinks = this.baseUrl + "admin/social-media/update";
  

  // !============================================  Contact Management ==========================================
  public static allContactsList = this.baseUrl + "admin/community-contact/list";
  public static contactsDetail =
    this.baseUrl + "admin/community-contact/detail/";
  public static contactsEdit = this.baseUrl + "admin/community-contact/edit";
  public static addContacts = this.baseUrl + "admin/community-contact/add";
  public static changeContactManagementStatus =
    this.baseUrl + "admin/community-contact/update-status";

  // !============================================  About Community ==========================================
  public static aboutCommunity = this.baseUrl + "";

  // !============================================  Sanstha ==========================================
  public static allSansthaList = this.baseUrl + "admin/sanstha/list";
  public static sansthaDetail = this.baseUrl + "admin/sanstha/detail/";
  public static sansthaEdit = this.baseUrl + "admin/sanstha/edit";
  public static addSanstha = this.baseUrl + "admin/sanstha/add";
  public static changeSansthaStatus =
    this.baseUrl + "admin/sanstha/sanstha-status-change";

  // !============================================  Emergency Contact Number ==========================================
  public static allEmergencyContactNumberList =
    this.baseUrl + "admin/emergency-group/list";
  public static emergencyContactNumberDetail =
    this.baseUrl + "admin/emergency-group/detail/";
  public static emergencyContactNumberEdit =
    this.baseUrl + "admin/emergency-group/edit";
  public static addEmergencyContactNumber =
    this.baseUrl + "admin/emergency-group/add";
  public static changeEmergencyContactStatus =
    this.baseUrl + "admin/emergency-group/update-status";

  // !============================================  Community Team ==========================================
  public static allCommunityTeamList =
    this.baseUrl + "admin/community-team/list";
  public static communityTeamDetail =
    this.baseUrl + "admin/community-team/detail/";
  public static communityTeamEdit = this.baseUrl + "admin/community-team/edit";
  public static addCommunityTeam = this.baseUrl + "admin/community-team/add";
  public static updateCommunityTeamImage =
    this.baseUrl + "admin/community-team/update-image";

  // !============================================  Policies => Privacy Policy ==========================================
  public static privacyPolicy = this.baseUrl + "";

  // !============================================  Policies => Terms & Conditions ==========================================
  public static termsAndConditions = this.baseUrl + "";

  // !============================================  FAQ ==========================================
  public static allFaqList = this.baseUrl + "admin/faq/list";
  public static faqDetail = this.baseUrl + "admin/faq/detail/";
  public static faqEdit = this.baseUrl + "admin/faq/edit";
  public static addFaq = this.baseUrl + "admin/faq/add";

  // !============================================  Staff Management=>Roles ==========================================\
  public static allRolesList = this.baseUrl + "admin/role/list";
  public static rolesDetail = this.baseUrl + "admin/role/detail/";
  public static rolesEdit = this.baseUrl + "admin/role/edit";
  public static addRoles = this.baseUrl + "admin/role/add";
  public static getAvailabelRole = this.baseUrl + "admin/role/available_list";

  // !============================================  Staff Management=>User ==========================================\
  public static allUserList = this.baseUrl + "admin/user/list";
  public static userDetail = this.baseUrl + "admin/user/detail/";
  public static userEdit = this.baseUrl + "admin/user/edit";
  public static addUser = this.baseUrl + "admin/user/add";
}
