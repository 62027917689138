import { Component } from '@angular/core';

@Component({
  selector: 'app-mct-loader',
  templateUrl: './mct-loader.component.html',
  styleUrls: ['./mct-loader.component.scss']
})
export class MctLoaderComponent {

}
