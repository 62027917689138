import { Injectable } from '@angular/core';
import { API } from '../API/api';
import { User } from '../model/user.model';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './shared.service';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';
import { NavService } from './nav.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private sharedService: SharedService, private router: Router, private navService: NavService) {}

  userData: User;

  getUserData() {
    this.sharedService.userData = JSON.parse(sessionStorage.getItem("user")!);
    // return this.userData;
  }

  login(user: { mobileNo: string; password: string }) {
    return this.http.post(API.login, user).pipe(catchError(this.sharedService.handleError));
  }

  signUp(body) {
    return this.http.post(API.register, body).pipe(catchError(this.sharedService.handleError));
  }

  public signOut() {
    sessionStorage.clear();
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("data1");
    this.navService.destroySideMenu()
    this.router.navigate(["/auth/login"]);
  }
}
