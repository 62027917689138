import { Pipe, PipeTransform } from '@angular/core';

// Search code filter Pipe 

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], filterString: string | number | null, propName: string): any {
    if (value.length === 0 || filterString == null || filterString === '') {
      return value;
    }
    let filterValue = typeof(filterString) == 'string' ? filterString.toLocaleLowerCase() : filterString
    const resultArray = [];
    for (const item of value) {
      let itemName =  typeof(filterString) == 'string' ? item[propName].toLocaleLowerCase().indexOf(filterValue) > -1 : item[propName] == filterValue
      
      if (itemName ) {
        resultArray.push(item);
      }
    }
    return resultArray;
  }
}