import { Injectable } from "@angular/core";
import { Post } from "../../model/post.model";
import { API } from "../../API/api";
import { catchError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { SharedService } from "../shared.service";
import { query } from '@angular/animations';

@Injectable({
  providedIn: "root",
})

export class SharedPostService {
  //fromJson
  fromJson(data: any) {
    const { postId, postBy, postDate, postDescription } = data;
    const postTitle = data.postTitle ?? null;
    const additionalNote = data.additionalNote ?? null;
    const likesCount = data.likesCount ?? null;
    const commentsCount = data.commentsCount ?? null;
    const likedBy = data.likedBy ?? null;
    const comments = data.comments ?? null;
    const isActive = data.isActive ?? null;
    const bannerImage = data.bannerImage ?? null;
    const moreImages = data.moreImages ?? null;
    const status = data.status ?? null;
    const reason = data.reason ?? null;
    const cancelledDate = data.cancelledDate ?? null;
    const familyMember = data.familyMember ?? null;
    const contactPerson = data.contactPerson ?? null;
    const funeralDateTime = data.funeralDateTime ?? null;
    const startDateTime = data.startDateTime ?? null;
    const endDateTime = data.endDateTime ?? null;
    const startAddress = data.startAddress ?? null;
    const endAddress = data.endAddress ?? null;
    const prayerStartTime = data.prayerStartTime ?? null;
    const prayerEndTime = data.prayerEndTime ?? null;
    const address = data.address ?? null;
    const postTypeId = data.postTypeId ?? null;

    let temp = new Post(
      postId,
      postBy,
      postDate,
      postDescription,
      postTypeId,
      postTitle,
      additionalNote,
      likesCount,
      commentsCount,
      likedBy,
      comments,
      isActive,
      bannerImage,
      moreImages,
      status,
      reason,
      cancelledDate,
      familyMember,
      contactPerson,
      funeralDateTime,
      startDateTime,
      endDateTime,
      startAddress,
      endAddress,
      prayerStartTime,
      prayerEndTime,
      // address
    );
    return temp;
  }

  getPostCommentList(id: number, pageNumber: number) {
    return this.http
      .get(API.getPostDetailCommentList + id, {
        params: {page: pageNumber},
        headers: {
          userId: `${this.sharedService.userData.userId}`,
          Authorization: `Bearer ${this.sharedService.userData.token}`,
        },
      })
      .pipe(catchError(this.sharedService.handleError));
  }
  getPostListList(id: number, pageNumber: number) {
    return this.http
      .get(API.getPostDetailLikeList + id, {
        params: {page: pageNumber},
        headers: {
          userId: `${this.sharedService.userData.userId}`,
          Authorization: `Bearer ${this.sharedService.userData.token}`,
        },
      })
      .pipe(catchError(this.sharedService.handleError));
  }

  constructor(private http: HttpClient, private sharedService: SharedService){}
}
