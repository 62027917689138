<div class="container-fluid">
  <div class="page-title">
    <div class="row justify-content-between">
      <div class="" [ngClass]="width_title">
        <h6 class="mb-0 py-2">{{ title }}</h6>
      </div>
      <div class="" [ngClass]="width_btn">
        <ng-content select=".action-buttons"></ng-content>
      </div>
    </div>
  </div>
</div>