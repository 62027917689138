<ng-template #viewModal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{ post.postTitle }}</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModel()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="model-view-image">
                <owl-carousel-o [options]="owlcarousel14Options">
                    <ng-template carouselSlide class="item">
                        <img [src]="viewImage" alt="">
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #moreImage >
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{ post.title }}</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModel()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="model-view-image custom-view">
                <owl-carousel-o [options]="owlcarouselOptions">
                    <ng-template carouselSlide class="item" [id]="image.moreImageId"
                        *ngFor="let image of post.post_detail.more_images">
                        <img [src]="image.imageUrl" alt="">
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</ng-template>

<div class="card preventive-card mb-2 h-100" *ngIf="post">
    <div class="card-header pt-2 pb-1 px-2">
        <div class="row">
            <div class="col d-flex justify-content-between align-items-center">
                <div class="d-flex ">
                    <div class="col-auto" style="z-index: 2;">
                        <div class="profile-box">
                            <!-- <img [src]="post.postTypeId == 3 || post.postTypeId == 4 || post.postTypeId == 5 || post.postTypeId == 6 ? 'assets/images/favicon.png' : 'https://media.istockphoto.com/id/1405522719/photo/man-sitting-indoor-looks-at-camera-participate-in-live-videoconference.webp?b=1&s=170667a&w=0&k=20&c=Dt8386bRyLdIZs_m586jKfbR3RaZ2M6evqqOnADCz-k='" -->
                            <img [src]="post.member_detail.profileImageUrl" alt="" width="100%" height="100%"
                                style="object-fit: cover;">
                        </div>
                    </div>
                    <div class="ms-2 col-auto" style="z-index: 2;">
                        <div class="user-name"><strong>{{ post.member_detail.fullName }}</strong></div>
                        <div class="d-flex"><small>{{ post.addedDate | customDate }}</small></div>
                    </div>
                </div>
                <div class="ms-2 col-auto text-end" style="z-index: 2;" *ngIf="post.status && displayStatusBadge">
                    <ng-container [ngSwitch]="post.status">
                        <!-- <ng-container *ngIf="post.postTypeId == 6">
                        </ng-container> -->
                        <h1 class="badge rounded-pill badge-warning" *ngSwitchCase="1">{{ 'Pending' | translate }}</h1>
                        <h1 class="badge rounded-pill badge-success" *ngSwitchCase="2">{{ 'Approved' | translate }}</h1>
                        <h1 class="badge rounded-pill badge-warning" *ngSwitchCase="3">{{ 'Revise' | translate }}</h1>
                        <h1 class="badge rounded-pill badge-danger" *ngSwitchCase="4">{{ 'Cancelled' | translate }}</h1>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>
    <div class=" card-body pt-2 pb-1 px-2">

        <!-- Banner Image -->
        <div class="col-12" *ngIf="post.post_detail.bannerImageUrl">
            <div class="img-box mb-2" (click)="openModel(viewModal, post.post_detail.bannerImageUrl)">
                <img [src]='post.post_detail.bannerImageUrl' alt="" class="img-fluid"
                    style="object-fit: cover; width: 100%;">
            </div>
        </div>

        <div class="col-12 " [ngClass]="post.post_detail.bannerImageUrl ? 'col-6' : 'col-12'">


            <!-- ================ Title / Description Section ================ -->
            <div *ngIf="post.post_detail.title"><strong>{{ post.post_detail.title }}</strong></div>
            <div
                *ngIf=" (post.postTypeId ==1) && post.post_detail.post_maran_detail && post.post_detail.post_maran_detail.personName">
                <strong>{{ post.post_detail.post_maran_detail.personName }}</strong>
            </div>

            <div class="text-secondary" *ngIf="post.post_detail.description">
                <small class="post-text-content">{{ post.post_detail.description | truncate: moreless ? 100 : null }}
                    <span (click)="moreLess()" class="text-primary" style="cursor: pointer;"
                        *ngIf="post.post_detail.description && post.post_detail.description.length > 100">...{{ moreless
                        ? 'read more' :
                        'read less' }}</span>
                </small>
            </div>

            <div class="text-secondary row" *ngIf=" (post.postTypeId ==1) && post.post_detail.post_maran_detail">
                <div class="col-md-12" *ngIf="post.post_detail.post_maran_detail.village">
                    <small class="post-text-content"> {{ 'Native Village of The Person' | translate }} : {{
                        post.post_detail.post_maran_detail.village }}</small>
                </div>
                <div class="col-md-auto">
                    <small class="post-text-content"> <!-- Age + Gender + Merital Status -->
                        <span *ngIf="post.post_detail.post_maran_detail.age">
                            {{post.post_detail.post_maran_detail.age}}{{'yrs' | translate }}</span>
                        <span *ngIf="post.post_detail.post_maran_detail.gender"><span *ngIf="post.post_detail.post_maran_detail.age">,</span> {{
                            ((post.post_detail.post_maran_detail.gender == 'Male')
                            ||(post.post_detail.post_maran_detail.gender == 'M')) ? ('Male' | translate) : ('Female' |
                            translate) }}</span>
                        <span *ngIf="post.post_detail.post_maran_detail.martialStatus">, {{
                            post.post_detail.post_maran_detail.martialStatus | translate }} </span>
                    </small>
                </div>
                <div class="col-md-12" *ngIf="post.post_detail.post_maran_detail.deathReason">
                    <small class="post-text-content"> {{ 'Reason of The Death' | translate }} : {{
                        post.post_detail.post_maran_detail.deathReason }}</small>
                </div>
                <div class="col-md-12" *ngIf="post.post_detail.post_maran_detail.deathDateTime">
                    <small class="post-text-content"> {{ 'Date & Time Of Death' | translate }} : {{
                        post.post_detail.post_maran_detail.deathDateTime | customDate}}</small>
                </div>
                <!--                 
                <div class="col-md-12" *ngIf="post.post_detail.post_maran_detail.martialStatus">
                    <small class="post-text-content"> {{ 'Marital Status' | translate }} : {{
                        post.post_detail.post_maran_detail.martialStatus | translate}}</small>
                </div>
                <div class="col-md-auto" *ngIf="post.post_detail.post_maran_detail.age">
                    <small class="post-text-content"> {{ 'Age' | translate }} : {{
                        post.post_detail.post_maran_detail.age }}</small>
                </div>
                <div class="col-md-auto" *ngIf="post.post_detail.post_maran_detail.gender">
                    <small class="post-text-content"> {{ 'Gender' | translate }} : {{
                        ((post.post_detail.post_maran_detail.gender == 'Male') ||
                        (post.post_detail.post_maran_detail.gender == 'M')) ? ('Male' | translate) : ('Female' | translate) }}</small>
                </div>
                -->
            </div>

            <div class=" mt-2 pt-2" style="border-top: 2px solid #bababa30;"
                *ngIf=" (post.postTypeId ==1) && (post.post_detail.post_maran_detail.martialStatus == 'Married') && post.post_detail.post_maran_detail && ( post.post_detail.post_maran_detail.fatherOrInLawName || post.post_detail.post_maran_detail.villageFatherOrInLaw)">
                <div class="row">
                    <div class="col-12"><strong>{{ 'Relation Details' | translate }}</strong> </div>
                    <div class="col-md-6 mt-1" *ngIf="post.post_detail.post_maran_detail.fatherOrInLawName">
                        <small class="post-text-content d-block">
                            {{
                            ((post.post_detail.post_maran_detail.gender == 'Male') ||
                            (post.post_detail.post_maran_detail.gender == 'M')) ? ('Father-in-law name' | translate) : ('Father name' | translate) }}
                        </small>
                        <small class=" text-secondary post-text-content"> {{
                            post.post_detail.post_maran_detail.fatherOrInLawName }}</small>
                    </div>
                    <div class="col-md-6 mt-1" *ngIf="post.post_detail.post_maran_detail.villageFatherOrInLaw">
                        <small class=" post-text-content d-block">
                            {{
                            ((post.post_detail.post_maran_detail.gender == 'Male') ||
                            (post.post_detail.post_maran_detail.gender == 'M')) ? ('Native Village of Father-in-law' |
                            translate ) : ('Native Village of Father' | translate) }}
                        </small>
                        <small class=" text-secondary post-text-content"> {{
                            post.post_detail.post_maran_detail.villageFatherOrInLaw }}</small>
                    </div>
                </div>
            </div>

            <!-- ================ Prayer Date/Time/Address Section ================ -->
            <ng-container *ngIf="post.postTypeId == 2">
                <div class="my-2"
                    *ngIf=" post.post_detail.post_maran_detail && post.post_detail.post_maran_detail.funeralPrayerStartTime">
                    <div>{{ 'Start Prathna Date Time' | translate }}</div>
                    <div class="text-secondary"><small>{{ post.post_detail.post_maran_detail.funeralPrayerDate +'
                            '+post.post_detail.post_maran_detail.funeralPrayerStartTime | customDate }}</small></div>
                </div>
                <div class="my-2"
                    *ngIf="post.post_detail.post_maran_detail && post.post_detail.post_maran_detail.funeralPrayerEndTime">
                    <div>{{ 'End Prathna Date Time' | translate }}</div>
                    <div class="text-secondary"><small>{{ post.post_detail.post_maran_detail.funeralPrayerDate +'
                            '+post.post_detail.post_maran_detail.funeralPrayerEndTime | customDate }}</small></div>
                </div>
                <div class="my-2" *ngIf="post.post_detail.post_maran_detail.startAddress">
                    <div>{{ 'Address' | translate }}</div>
                    <div class="text-secondary"><small>{{ post.post_detail.post_maran_detail.startAddress }}</small>
                    </div>
                </div>
                <div class="my-2" *ngIf="post.post_detail.post_maran_detail.homeAddress">
                    <div>{{ 'Home Address' | translate }}</div>
                    <div class="text-secondary"><small>{{ post.post_detail.post_maran_detail.homeAddress }}</small>
                    </div>
                </div>
            </ng-container>



            <!-- ================ Funeral Date/Time/Address Section ================ -->
            <ng-container *ngIf="post.postTypeId == 1">
                <ng-container>
                    <div class=" mt-2 pt-2" style="border-top: 2px solid #bababa30;"
                        *ngIf="post.post_detail.post_maran_detail && post.post_detail.post_maran_detail.funeralPrayerStartTime">
                        <div>{{ 'Funeral Date Time' | translate }}</div>
                        <div class="text-secondary"><small>{{ post.post_detail.post_maran_detail.funeralPrayerDate +'
                                '+post.post_detail.post_maran_detail.funeralPrayerStartTime | customDate }} </small>
                        </div>
                    </div>
                </ng-container>

                <ng-container>
                    <div class=" mt-2 pt-2" style="border-top: 2px solid #bababa30;"
                        *ngIf="post.post_detail.post_maran_detail.startAddress">
                        <div>{{ 'Start Address' | translate }}</div>
                        <div class="text-secondary"><small>{{ post.post_detail.post_maran_detail.startAddress }}</small>
                        </div>
                    </div>
                </ng-container>

                <ng-container>
                    <div class=" mt-2 pt-2" style="border-top: 2px solid #bababa30;"
                        *ngIf="post.post_detail.post_maran_detail.homeAddress">
                        <div>{{ 'Home Address' | translate }}</div>
                        <div class="text-secondary"><small>{{ post.post_detail.post_maran_detail.homeAddress }}</small>
                        </div>
                    </div>
                </ng-container>

                <ng-container>
                    <div class="my-2" *ngIf="post.post_detail.post_maran_detail.endAddress">
                        <div>{{ 'End Address' | translate }}</div>
                        <div class="text-secondary"><small>{{ post.post_detail.post_maran_detail.endAddress }}</small>
                        </div>
                    </div>
                </ng-container>
            </ng-container>


            <!-- More Images -->

            <div class="row px-2 mt-2" *ngIf="post.post_detail.more_images && post.postTypeId !=7">
                <ng-container *ngFor="let image of post.post_detail.more_images; index as i">
                    <!-- <div class="col-4 col-xl-2 mb-2 px-1 images-box" *ngIf="i <= 2"> -->
                    <div class="col-4 col-xl-2 mb-2 px-1 images-box">
                        <div class="images-box ratio ratio-1x1" (click)="openModel(moreImage,image.imageUrl)"><img
                                [src]="image.imageUrl" alt="" width="100%" height="100%" class="images"></div>
                    </div>
                </ng-container>
            </div>

            <div class="row px-2 mt-2" *ngIf="post.post_detail.more_images && post.postTypeId == 7">
                <ng-container *ngFor="let image of post.post_detail.more_images; let i = index">
                    <ng-container
                        *ngIf="post.post_detail.more_images.length <= 3 && post.post_detail.more_images.length != 2 && i == 0">
                        <div class="col-12 col-xl-12 mb-2 px-1 images-box">
                            <div class="images-box ratio ratio-1x1" (click)="openModel(moreImage,image.imageUrl)"><img
                                    [src]="image.imageUrl" alt="" width="100%" height="100%" class="images"></div>
                        </div>
                    </ng-container>

                    <!-- for more then 4 image -->
                    <div class="col-12 col-xl-6 mb-2 px-1 images-box position-relative"
                        *ngIf="post.post_detail.more_images.length == 2 || (post.post_detail.more_images.length == 3 && i > 0) || (post.post_detail.more_images.length >= 4 && i <=3)">
                        <div class="images-box ratio ratio-1x1" (click)="openModel(moreImage,image.imageUrl)">
                            <img [src]="image.imageUrl" alt="" width="100%" height="100%" class="images">
                            <div class="overlay position-absolute" *ngIf="i == 3">
                                <p class="fs-1">+ {{ (post.post_detail.more_images.length - 4) }}</p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>


        </div>

        <div class="col-12 mt-2"
            *ngIf="((post.postTypeId ==1) || (post.postTypeId ==2)) && post.post_detail.post_maran_detail && post.post_detail.post_maran_detail.additionalNote">
            <small class=" post-text-content">{{'Note' | translate}} : </small>
            <small class=" text-secondary post-text-content"> {{ post.post_detail.post_maran_detail.additionalNote
                }}</small>
        </div>
       
        <!-- ================  Relatives Contact Section ================ -->
        <!-- <div class="" > -->
        <div class="col-12 row mx-0 pt-2 mt-2"
            *ngIf="post.post_detail.contact_persons && post.post_detail.contact_persons.length > 0"
            style=" border-top: 2px solid #bababa30;">
            <div class="col-12 p-0">{{ 'Contact Person' | translate }}</div>
            <ng-container *ngFor="let contact of post.post_detail.contact_persons; index as i">
                <div class="col-12 p-0 py-1" style="color: #727272;">
                    <div class="row justify-content-between">
                        <div class="col-12 text-start">
                            <span>{{ contact.personName }}</span>
                        </div>
                        <div class="col-12 text-start">
                            <span>{{ contact.contactNumber }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- </div> -->

         <!-- ================ Family Members Image Section ================ -->
        <!-- <div class=""  > -->
            <div class="col-12 row mx-0 pt-2 mt-2"
            *ngIf="post.post_detail.family_members && post.post_detail.family_members.length > 0"
            style=" border-top: 2px solid #bababa30;">
            <div class="col-12 p-0 mb-1">{{ 'Family Member' | translate }}</div>
            <ng-container *ngFor="let member of post.post_detail.family_members; index as i">
                <div class="col-12 col-md-6 mb-2 p-0 pe-2 d-flex">
                    <div class="col-3 col-md-2">
                        <div class="images-box ratio ratio-1x1"><img [src]="member.memberImageUrl" alt="" width="100%"
                                height="100%" class="images"></div>
                    </div>
                    <div class="col-10 ps-3 d-flex flex-column justify-content-center ">
                        <div style="font-size:14px"> {{ member.memberName }}</div>
                        <div class="text-secondary" style="line-height: 8px;"> <small>{{ member.relation
                                }}</small>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- </div> -->
    </div>
    <div class="card-footer py-2 px-2  d-none d-md-block" *ngIf="displayLikeAndComments">
        <div>
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li [ngbNavItem]="'like'" class="like-list-li">
                    <a ngbNavLink>
                        <div class="d-flex align-item-center justify-content-center"
                            *ngIf="(post.postTypeId != 1) && (post.postTypeId != 2) && (post.postTypeId != 6)">
                            <i class="fa fa-thumbs-o-up" style="font-size: 20px; color:#ff1e66;" *ngIf="!postLike"></i>
                            <i class="fa fa-thumbs-up" style="font-size: 21px; color:#ff1e66;" *ngIf="postLike"></i>
                            <span class="ms-2"><small style="font-weight: 500;">{{ post.likes_count }}
                                    {{ 'Likes' | translate }}</small></span>
                        </div>
                        <div class="d-flex align-item-center justify-content-center"
                            *ngIf="post.postTypeId == 1 || post.postTypeId == 2">
                            <img src="assets/images/emoji/hands.png" alt="" class="img-fluid" style="width:16px">
                            <span class="ms-2"><small style="font-weight: 500;">{{ post.likes_count }}</small></span>
                        </div>
                        <div class="d-flex align-item-center justify-content-center" *ngIf="post.postTypeId == 6">
                            <img src="assets/images/emoji/confetti.png" alt="" class="img-fluid" style="width:16px">
                            <span class="ms-2"><small style="font-weight: 500;">{{ post.likes_count }}
                                    {{ 'Likes' | translate }}</small></span>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="likes-box">
                            <ng-container *ngFor="let user of likesList; let i = index">
                                <div class="row py-2 mx-0">
                                    <div class="col-auto">
                                        <div class="avatar d-flex items-center">
                                            <img class="img-30 rounded-circle" [src]="user.liked_by.profileImageUrl ? user.liked_by.profileImageUrl : 'assets/images/avtar/user.png'" alt="#"
                                                style="object-fit: cover; aspect-ratio:1/1" />
                                        </div>
                                    </div>
                                    <div class="col-10 col-md-11 col-xl-9 px-xl-0">
                                        <div class="">
                                            <div class="fw-medium lh-1">{{ user.liked_by.fullName }}</div>
                                            <small class="text-gray">{{ getTimeAgo(user.addedDate) }}</small>
                                        </div>
                                        <!-- <div><small>{{ user.liked_by.fullName }}</small></div> -->
                                    </div>
                                </div>
                            </ng-container>
                            <p class="text-center" style="color: #5272f2; cursor:pointer" (click)="fetchLikeList()" *ngIf="(likeCurrentPage < likeTotalPage) && !isLoading">Load more</p>
                            <div class="loader-box" *ngIf="isLoading">
                                <div class="loader-5"></div>
                              </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="'comment'" class="comments-list-li">
                    <a ngbNavLink>
                        <div class="d-flex align-item-center justify-content-center">
                            <svg style="color: #5272f2;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                fill="currentColor" class="bi bi-chat-text-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                            </svg><span class="ms-2"><small>{{ post.comments_count }} {{ 'comments' | translate
                                    }}</small></span>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="comments-box">
                            <ng-container *ngFor="let comment of commentList;index as i">
                                <!-- <div class="row py-2 mx-0" *ngIf="i <= 2">
                                    <div class="col-auto">
                                        <div class="avatar">
                                            <img class="img-30 rounded-circle" [src]="comment.profileImg" alt="#"
                                                style="object-fit: cover; aspect-ratio:1/1" />
                                        </div>
                                    </div>
                                    <div class="col-10 col-md-11 col-xl-9 px-xl-0">
                                        <div>{{ comment.name }}</div>
                                        <div style="line-height: 12px;">
                                            <small>{{ comment.comment}}</small>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="row py-2 mx-0">
                                    <div class="col-auto">
                                        <div class="avatar">
                                            <img class="img-30 rounded-circle" [src]="comment.commented_by.profileImageUrl ? comment.commented_by.profileImageUrl : 'assets/images/avtar/user.png'" alt="#"
                                                style="object-fit: cover; aspect-ratio:1/1" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex justify-content-between">
                                            <div class="fw-medium">{{ comment.commented_by.fullName }}</div>
                                            <div class="d-flex" style="font-size: 12px;">
                                                <span class="text-gray">{{ getTimeAgo(comment.addedDate) }}</span>
                                            </div>
                                        </div>
                                        <div style="line-height: 12px;">
                                            <small>{{ comment.commentText }}</small>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <p class="text-center" style="color: #5272f2; cursor:pointer" (click)="fetchCommentList()" *ngIf="(commentCurrentPage < commentTotalPage) && !isLoading">Load more</p>
                            <div class="loader-box" *ngIf="isLoading">
                                <div class="loader-5"></div>
                              </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2" style="max-height: 50vh; overflow: auto"></div>
        </div>
    </div>
</div>