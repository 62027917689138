import { Component } from '@angular/core';

@Component({
  selector: 'app-mct-btn-loader',
  templateUrl: './mct-btn-loader.component.html',
  styleUrls: ['./mct-btn-loader.component.scss']
})
export class MctBtnLoaderComponent {

}
