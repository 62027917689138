<div class="card preventive-card mb-2 h-100">
    <div class="card-header pt-2 pb-1 px-2">
        <div class="row">
            <div class="col d-flex">
                <div class="col-auto" style="z-index: 2;">
                    <div class="profile-box">
                        <img [src]="post.postTypeId == 1 || post.postTypeId == 2 || post.postTypeId == 3 ? 'assets/images/favicon.png' : 'https://media.istockphoto.com/id/1405522719/photo/man-sitting-indoor-looks-at-camera-participate-in-live-videoconference.webp?b=1&s=170667a&w=0&k=20&c=Dt8386bRyLdIZs_m586jKfbR3RaZ2M6evqqOnADCz-k='"
                            alt="" width="100%" height="100%" style="object-fit: cover;">
                    </div>
                </div>
                <div class="ms-2 col-auto" style="z-index: 2;">
                    <div>
                        <div class="user-name"><strong>{{ post.addedBy }}</strong></div>
                        <div class="d-flex"><small>{{ post.addedDate | date:'dd-MM-yyyy HH:mm' }}</small></div>
                    </div>

                    <div>
                        <div class="options-dropdown-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path
                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                            </svg>
                            <div class="options-dropdown">
                                <div class="card card-body options-card text-center p-0">
                                    <a [routerLink] ="[item.link,post.postId]" class="options"
                                        *ngFor="let item of Items">{{ item.option }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=" card-body pt-2 pb-1 px-2">
        <!-- ================ Banner Image Section ================ -->
        <div>
            <div class="img-box mb-2" *ngIf="post.bannerImageUrl">
                <img [src]='post.bannerImageUrl' alt="" width="100%" style="object-fit: contain;">
            </div>
        </div>
        <!-- ================ Title / Description Section ================ -->
        <div *ngIf="post.title"><strong>{{ post.title }}</strong></div>
        <div class="text-secondary">
            <small class="post-text-content">{{ post.description | truncate:30 }}...</small>
        </div>
        <!-- ================ Prayer Date/Time/Address Section ================ -->
        <div class="my-2" *ngIf="post.prayerStartTime">
            <div>Start Prayer Date Time</div>
            <div class="text-secondary"><small>{{ post.prayerStartTime | date:'dd-MM-yyyy HH:mm' }}</small></div>
        </div>
        <div class="my-2" *ngIf="post.prayerEndTime">
            <div>End Prayer Date Time</div>
            <div class="text-secondary"><small>{{ post.prayerEndTime | date:'dd-MM-yyyy HH:mm' }}</small></div>
        </div>
        <div class="my-2" *ngIf="post.address">
            <div>Address</div>
            <div class="text-secondary"><small>{{ post.address }}</small></div>
        </div>
        <!-- ================ Funeral Date/Time/Address Section ================ -->
        <div class="my-2" *ngIf="post.funeralDateTime">
            <div>Funeral Date Time</div>
            <div class="text-secondary"><small>{{ post.funeralDateTime | date:'dd-MM-yyyy HH:mm' }}</small></div>
        </div>
        <div class="my-2" *ngIf="post.startAddress">
            <div>Start Address</div>
            <div class="text-secondary"><small>{{ post.startAddress }}</small></div>
        </div>
        <div class="my-2" *ngIf="post.endAddress">
            <div>End Address</div>
            <div class="text-secondary"><small>{{ post.endAddress }}</small></div>
        </div>

        <!-- ================ More Image Section ================ -->
        <div class="row px-2 mt-2" *ngIf="post.more_images">
            {{ image + 'hello world' }}
            <ng-container *ngFor="let image of post.more_images; index as i">
                <div class="col-4 mb-2 px-1 images-box" *ngIf="i <= 2">
                    <!-- <img [src]="moreImg.img" alt="" width="100%" class="images ratio ratio-1x1"
                        style="object-fit: contain;"> -->
                    <div class="images-box ratio ratio-1x1"><img [src]="image.imageUrl" alt="" width="100%" height="100%"
                            class="images"></div>
                    <div class="img-counts-overlay d-flex justify-content-center align-items-center" *ngIf="i == 2">
                        <span class="img-counts">3+</span>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- ================ Family Members Image Section ================ -->
        <div>
            <div class="col-12 row mx-0" *ngIf="post.family_members">
                <div class="col-12 p-0 mb-1">Family Member</div>
                <div class="d-flex famiy-member-scroll p-0">
                    <div class="col-3 col-md-3 mb-2 p-0 pe-2 " *ngFor="let member of post.family_members; index as i">
                        <div class="images-box ratio ratio-1x1"><img [src]="member.memberImageUrl" alt="" width="100%"
                                height="100%" class="images"></div>
                        <div class="text-center">
                            <div> <small><strong>{{ member.memberName }}</strong></small></div>
                            <div class="text-secondary" style="line-height: 8px;"> <small>{{ member.relation
                                    }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ================  Relatives Contact Section ================ -->
    </div>
    <!-- ================ footer Section ================ -->

    <div class="card-footer py-2 px-2 h-100 d-none d-md-block" *ngIf="!post.bannerImageUrl">
        <div>
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li [ngbNavItem]="'like'" class="like-list-li">
                    <a ngbNavLink>
                        <div class="d-flex align-item-center"  *ngIf="(post.postTypeId != 3) && (post.postTypeId != 4) && (post.postTypeId != 5)">
                            <i class="fa fa-thumbs-o-up" style="font-size: 20px; color:#ff1e66;" *ngIf="!postLike"></i>
                            <i class="fa fa-thumbs-up" style="font-size: 21px; color:#ff1e66;" *ngIf="postLike"></i>
                            <span class="ms-2"><small style="font-weight: 500;">{{ post.likesCount }} Likes</small></span>
                        </div>
                        <div class="d-flex align-item-center" *ngIf="post.postTypeId == 4 || post.postTypeId == 5">
                            <img src="assets/images/emoji/hands.png" alt="" class="img-fluid" style="width:16px">
                            <span class="ms-2"><small style="font-weight: 500;">{{ post.likesCount }} Likes</small></span>
                        </div>
                        <div class="d-flex align-item-center" *ngIf="post.postTypeId == 3">
                            <img src="assets/images/emoji/confetti.png" alt="" class="img-fluid" style="width:16px">
                            <span class="ms-2"><small style="font-weight: 500;">{{ post.likesCount }} Likes</small></span>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="likes-box">
                            <div class="row py-2 mx-0" *ngFor="let data of post.likedBy">
                                <div class="col-auto">
                                    <div class="avatar">
                                        <img class="img-30 rounded-circle" [src]="data.profileImage" alt="#"
                                            style="object-fit: cover; aspect-ratio:1/1" />
                                    </div>
                                </div>
                                <div class="col-10 col-md-11 col-xl-9 px-xl-0 d-flex align-items-center">
                                    <!-- <div>{{ data.user_id }}</div> -->
                                    <div style="line-height: 12px;"><small>{{ data.name }}</small></div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="'comment'" class="comments-list-li">
                    <a ngbNavLink>
                        <div class="d-flex align-item-center justify-content-end">
                            <svg style="color:#7366ff;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                fill="currentColor" class="bi bi-chat-text-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                            </svg><span class="ms-2"><small>{{ post.likesCount }} Comments</small></span>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="comments-box">
                            <ng-container *ngFor="let comment of post.comments;index as i">
                                <div class="row py-2 mx-0" *ngIf="i <= 2">
                                    <div class="col-auto">
                                        <div class="avatar">
                                            <img class="img-30 rounded-circle" [src]="comment.profileImg" alt="#"
                                                style="object-fit: cover; aspect-ratio:1/1" />
                                        </div>
                                    </div>
                                    <div class="col-10 col-md-11 col-xl-9 px-xl-0">
                                        <div>{{ comment.name }}</div>
                                        <div style="line-height: 12px;">
                                            <small>{{ comment.comment }}</small>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
    <div class="card-footer py-2 px-2 d-none d-md-block" *ngIf="post.bannerImageUrl">
        <div class="d-flex justify-content-between">
            <div class="d-flex align-item-center"  *ngIf="(post.postTypeId != 3) && (post.postTypeId != 4) && (post.postTypeId != 5)">
                <i class="fa fa-thumbs-o-up" style="font-size: 20px; color:#ff1e66;" *ngIf="!postLike"></i>
                <i class="fa fa-thumbs-up" style="font-size: 21px; color:#ff1e66;" *ngIf="postLike"></i>
                <span class="ms-2"><small style="font-weight: 500;">{{ post.likesCount }} Likes</small></span>
            </div>
            <div class="d-flex align-item-center" *ngIf="post.postTypeId == 4 || post.postTypeId == 5">
                <img src="assets/images/emoji/hands.png" alt="" class="img-fluid" style="width:16px">
                <span class="ms-2"><small style="font-weight: 500;">{{ post.likesCount }} Likes</small></span>
            </div>
            <div class="d-flex align-item-center" *ngIf="post.postTypeId == 3">
                <img src="assets/images/emoji/confetti.png" alt="" class="img-fluid" style="width:16px">
                <span class="ms-2"><small style="font-weight: 500;">{{ post.likesCount }} Likes</small></span>
            </div>
            <div class="d-flex align-item-center">
                <svg style="color:#7366ff;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    fill="currentColor" class="bi bi-chat-text-fill" viewBox="0 0 16 16">
                    <path
                        d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                </svg><span class="ms-2"><small style="font-weight: 500;">{{ post.commentsCount }} Comments</small></span>
            </div>
        </div>
    </div>
    <div class="card-footer py-2 px-2 d-md-none">
        <div class="d-flex justify-content-between">
            <div class="d-flex align-item-center"  *ngIf="(post.postTypeId != 3) && (post.postTypeId != 4) && (post.postTypeId != 5)">
                <i class="fa fa-thumbs-o-up" style="font-size: 20px; color:#ff1e66;" *ngIf="!postLike"></i>
                <i class="fa fa-thumbs-up" style="font-size: 21px; color:#ff1e66;" *ngIf="postLike"></i>
                <span class="ms-2"><small style="font-weight: 500;">{{ post.likesCount }} Likes</small></span>
            </div>
            <div class="d-flex align-item-center" *ngIf="post.postTypeId == 4 || post.postTypeId == 5">
                <img src="assets/images/emoji/hands.png" alt="" class="img-fluid" style="width:16px">
                <span class="ms-2"><small style="font-weight: 500;">{{ post.likesCount }} Likes</small></span>
            </div>
            <div class="d-flex align-item-center" *ngIf="post.postTypeId == 3">
                <img src="assets/images/emoji/confetti.png" alt="" class="img-fluid" style="width:16px">
                <span class="ms-2"><small style="font-weight: 500;">{{ post.likesCount }} Likes</small></span>
            </div>
            <div class="d-flex align-item-center">
                <svg style="color:#7366ff;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    fill="currentColor" class="bi bi-chat-text-fill" viewBox="0 0 16 16">
                    <path
                        d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                </svg><span class="ms-2"><small style="font-weight: 500;">{{ post.commentsCount }} Comments</small></span>
            </div>
        </div>
    </div>
</div>