import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Post } from '../../model/post.model';
import { SharedPostService } from '../../services/post/shared-post.service';

@Component({
  selector: 'app-view-card',
  templateUrl: './detail-post-card.component.html',
  styleUrls: ['./detail-post-card.component.scss']
})
export class DetailPostCardComponent implements OnInit {
  postLike = true;
  disabled = true;
  viewImage: string = null;
  modalReference: any;
  commentList: any[] = []
  likesList: any[] = []
  commentCurrentPage: number = 0
  likeCurrentPage: number = 0
  commentTotalPage: number = 0
  likeTotalPage: number = 0
  isLoading: boolean = false
  @Input() displayStatusBadge: boolean = true
  @Input() displayLikeAndComments: Boolean = true

  @Input() post: Post;
  constructor(config: NgbModalConfig, private modalService: NgbModal, private sharedPostService: SharedPostService) {
    config.size = 'xl';
    config.backdrop = 'static';
    config.centered = true
  }
  ngOnInit(): void {
    if (this.post) {
      this.fetchCommentList();
      this.fetchLikeList();
    }
  }

  fetchCommentList() {
    this.isLoading = true
    this.sharedPostService.getPostCommentList(this.post.postId, this.commentCurrentPage + 1).subscribe({
      next: (res) => {
        this.commentList = [...this.commentList, ...res['data']['data']];
        // console.log(res);
        this.commentCurrentPage = res['data']['current_page'];
        this.commentTotalPage = res['data']['last_page'];
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      }
    })
  }

  fetchLikeList() {
    this.isLoading = true
    this.sharedPostService.getPostListList(this.post.postId, this.likeCurrentPage + 1).subscribe({
      next: (res) => {
        this.likesList = [...this.likesList, ...res['data']['data']];
        // console.log(this.likesList);
        this.likeCurrentPage = res['data']['current_page'];
        this.likeTotalPage = res['data']['last_page'];
        this.isLoading = false
      },
      error: (error) => {
        this.isLoading = false
        console.log(error);
      }
    })
  }

  openModel(content, img?) {
    this.modalReference = this.modalService.open(content)
    this.viewImage = img
  }
  closeModel() {
    this.modalReference.close();
    this.viewImage = null
  }
  moreless: boolean = true;
  moreLess() {
    this.moreless = !this.moreless
  }

  owlcarousel14Options = {
    items: 1,
    // margin: 10,
    // autoHeight: true,
    nav: false,
    // URLhashListener:true,
    // startPosition: 'URLHash'
  }
  owlcarouselOptions = {
    items: 1,
    // margin: 10,
    // autoHeight: true,
    nav: true,
    URLhashListener: true,
    startPosition: 'URLHash'
  }


  getTimeAgo(date) {
    let now = new Date();
    let difference = now.getTime() - new Date(date.split(' ').join('T')).getTime();
    if (difference > 365 * 24 * 3600 * 1000) {
      return `${Math.floor(difference / (365 * 24 * 3600 * 1000))} ${Math.floor(difference / (365 * 24 * 3600 * 1000)) === 1 ? "year" : "years"} ago`;
    } else if (difference > 30 * 24 * 3600 * 1000) {
      return `${Math.floor(difference / (30 * 24 * 3600 * 1000))} ${Math.floor(difference / (30 * 24 * 3600 * 1000)) === 1 ? "month" : "months"} ago`;
    } else if (difference > 7 * 24 * 3600 * 1000) {
      return `${Math.floor(difference / (7 * 24 * 3600 * 1000))} ${Math.floor(difference / (7 * 24 * 3600 * 1000)) === 1 ? "week" : "weeks"} ago`;
    } else if (difference > 24 * 3600 * 1000) {
      return `${Math.floor(difference / (24 * 3600 * 1000))} ${Math.floor(difference / (24 * 3600 * 1000)) === 1 ? "day" : "days"} ago`;
    } else if (difference > 3600 * 1000) {
      return `${Math.floor(difference / (3600 * 1000))} ${Math.floor(difference / (3600 * 1000)) === 1 ? "hour" : "hours"} ago`;
    } else if (difference > 60 * 1000) {
      return `${Math.floor(difference / (60 * 1000))} ${Math.floor(difference / (60 * 1000)) === 1 ? "minute" : "minutes"} ago`;
    } else if (difference > 1000) {
      return `${Math.floor(difference / 1000)} ${Math.floor(difference / 1000) === 1 ? "second" : "seconds"} ago`;
    }
    return "just now";
  }
}
