import { Injectable } from "@angular/core";
import { Post } from "../../model/post.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API } from "../../API/api";
import { SharedService } from "../../services/shared.service";
import { catchError } from "rxjs";
import { SharedPostService } from "./shared-post.service";
import { query } from '@angular/animations';

@Injectable({
  providedIn: "root",
})
export class CommentService {
  private comment: Post[] = [];
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  //getCommentList
  getCommentList(status, page) {
    const query ={
      status,
      page
    }
    return this.http
      .get(API.allCommentsList, {
        params: query,
        headers: {
          userId: `${this.sharedService.userData.userId}`,
          Authorization: `Bearer ${this.sharedService.userData.token}`,
        },
      })
      .pipe(catchError(this.sharedService.handleError));
  }

  // getCommentDetail
  getCommentDetail(commentId, postId) {
    let urlWithPostId = API.commentsDetailWithPost.replace('[postId]', postId)
    let URL = urlWithPostId.replace('[commentId]', commentId)
    return this.http
      .get(URL, {
        headers: {
          userId: `${this.sharedService.userData.userId}`,
          Authorization: `Bearer ${this.sharedService.userData.token}`,
        },
      })
      .pipe(catchError(this.sharedService.handleError));
  }

  updateCommentStatus(body){
    return this.http
    .post(API.updateCommentStatus, body, {
      headers: {
        userId: `${this.sharedService.userData.userId}`,
        Authorization: `Bearer ${this.sharedService.userData.token}`,
      },
    })
    .pipe(catchError(this.sharedService.handleError));
  }
}
