import { Component,Input } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feedback-view-card',
  templateUrl: './feedback-view-card.component.html',
  styleUrls: ['./feedback-view-card.component.scss']
})
export class FeedbackViewCardComponent {
  postLike = true;
  disabled = true;
  viewImage:string = null;
  modalReference: any;
  @Input() title = '';
  @Input() viewData;
  @Input() data_list;
  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    config.size = 'xl';
    config.backdrop = 'static';
    config.centered = true
  }

  openModel(content,img) {
    this.modalReference = this.modalService.open(content)
    this.viewImage = img
  }
  closeModel() {
    this.modalReference.close();
    this.viewImage = null
  }
  images = ['assets/images/big-lightgallry/01.jpg','assets/images/big-lightgallry/02.jpg','assets/images/big-lightgallry/03.jpg']
}
