<ng-template #viewModal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{ post.postTitle }}</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModel()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="model-view-image">
                <img [src]="viewImage" alt="" class="" width="100%" height="100%" style="object-fit: contain;">
            </div>
        </div>
    </div>
</ng-template>

<ng-template #statusChangeModal let-modal> <!-- Single Status Update -->
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ 'Update Status' | translate }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="updateStatusCanceled()"></button>
    </div>
    <div class="modal-body">
        <p>{{ 'Do you really want to update the status?' | translate }} </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="updateStatus()">{{ 'Yes' | translate }}</button>
        <button type="button" class="btn btn-outline-danger" (click)="updateStatusCanceled()">{{ 'No' | translate
            }}</button>
    </div>
</ng-template>

<div class="card preventive-card mb-2 h-100" *ngIf="post">
    <div class="card-body py-2 px-2 d-block">
        <div class="col-12">
            <div class="comments-box">
                <ng-container *ngFor="let comment of post.comments;index as index">
                    <div class="row py-2 mb-2 mx-0 selected-comment-box" *ngIf="index == 0">
                        <div class="col-auto">
                            <div class="avatar">
                                <img class="rounded-circle" [src]="comment.commented_by.profileImageUrl ? comment.commented_by.profileImageUrl :'assets/images/avtar/user.png' " alt="#"
                                    style="object-fit: cover; aspect-ratio:1/1; width: 3rem;" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="d-flex justify-content-between">
                                <div class="active-user">{{ comment.commented_by.fullName }}</div>
                                <div class="d-flex" style="font-size: 12px;">
                                    <span>{{ comment.time }}</span>
                                    <span class="ms-3">
                                        <div
                                            class="action-buttons d-flex justify-content-center align-items-center gap-3">
                                            <div class="media-body switch-sm d-flex align-items-center gap-3">
                                                <label class="switch m-0">
                                                    <input type="checkbox" [checked]="comment.isActive == 1" (change)="statusChanged($event, index)"><span
                                                        class="switch-state">
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div style="line-height: 12px;">
                                <small style=" color: #544ea9; font-size: 11px;">{{ comment.commentText }}</small>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div>
            <ul ngbNav #nav="ngbNav" [activeId]="'comment'" class="nav-tabs">
                <li [ngbNavItem]="'comment'" class="comments-list-li">
                    <a ngbNavLink>
                        <div class="d-flex align-item-center justify-content-center">
                            <svg style="color:#7366ff;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                fill="currentColor" class="bi bi-chat-text-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                            </svg><span class="ms-2"><small>{{ post.comments_count }} comments</small></span>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="comments-box">
                            <ng-container *ngFor="let comment of commentList;index as index">
                                <div class="row py-2 mx-0" *ngIf="index >= 1">
                                    <div class="col-auto">
                                        <div class="avatar">
                                            <img class="img-30 rounded-circle" [src]="comment.commented_by.profileImageUrl ?comment.commented_by.profileImageUrl : 'assets/images/avtar/user.png'" alt="#"
                                                style="object-fit: cover; aspect-ratio:1/1" />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex justify-content-between">
                                            <div>{{ comment.commented_by.fullName }}</div>
                                            <div class="d-flex" style="font-size: 12px;">
                                                <span>{{ getTimeAgo(comment.addedDate) }}</span>
                                                <span class="ms-3">
                                                    <div
                                                        class="action-buttons d-flex justify-content-center align-items-center gap-3">
                                                        <div
                                                            class="media-body switch-sm d-flex align-items-center gap-3">
                                                            <label class="switch m-0">
                                                                <input type="checkbox" [checked]="comment.isActive == 1" (change)="statusChanged($event, index)"><span
                                                                    class="switch-state">
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div style="line-height: 12px;">
                                            <small>{{ comment.commentText }}</small>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <p class="text-center" style="color: #5272f2; cursor:pointer" (click)="fetchCommentList()" *ngIf="(commentCurrentPage < commentTotalPage) && !isLoading">Load more</p>
                <div class="loader-box" *ngIf="isLoading">
                    <div class="loader-5"></div>
                  </div>
                       
                    </ng-template>
                </li>
                <li [ngbNavItem]="'like'" class="like-list-li">
                    <!-- <a ngbNavLink>
                        <div class="d-flex align-item-center justify-content-center"
                            *ngIf="(post.postTypeId != 3) && (post.postTypeId != 4) && (post.postTypeId != 5)">
                            <i class="fa fa-thumbs-o-up" style="font-size: 20px; color:#ff1e66;" *ngIf="!postLike"></i>
                            <i class="fa fa-thumbs-up" style="font-size: 21px; color:#ff1e66;" *ngIf="postLike"></i>
                            <span class="ms-2"><small style="font-weight: 500;">{{ post.likes_count }}
                                    Likes</small></span>
                        </div>
                        <div class="d-flex align-item-center justify-content-center"
                            *ngIf="post.postTypeId == 4 || post.postTypeId == 5">
                            <img src="assets/images/emoji/hands.png" alt="" class="img-fluid" style="width:16px">
                            <span class="ms-2"><small style="font-weight: 500;">{{ post.likes_count }}</small></span>
                        </div>
                        <div class="d-flex align-item-center justify-content-center" *ngIf="post.postTypeId == 3">
                            <img src="assets/images/emoji/confetti.png" alt="" class="img-fluid" style="width:16px">
                            <span class="ms-2"><small style="font-weight: 500;">{{ post.likes_count }}
                                    Likes</small></span>
                        </div>
                    </a> -->
                    <a ngbNavLink>
                        <div class="d-flex align-item-center justify-content-center"
                            *ngIf="(post.postTypeId != 1) && (post.postTypeId != 2) && (post.postTypeId != 6)">
                            <i class="fa fa-thumbs-o-up" style="font-size: 20px; color:#ff1e66;" *ngIf="!postLike"></i>
                            <i class="fa fa-thumbs-up" style="font-size: 21px; color:#ff1e66;" *ngIf="postLike"></i>
                            <span class="ms-2" *ngIf="post.likes_count > 0"><small style="font-weight: 500;">{{ post.likes_count }}
                                    {{ 'Likes' | translate }}</small></span>
                        </div>
                        <div class="d-flex align-item-center justify-content-center"
                            *ngIf="post.postTypeId == 1 || post.postTypeId == 2">
                            <img src="assets/images/emoji/hands.png" alt="" class="img-fluid" style="width:16px">
                            <span class="ms-2" *ngIf="post.likes_count > 0"><small style="font-weight: 500;">{{ post.likes_count }}</small></span>
                        </div>
                        <div class="d-flex align-item-center justify-content-center" *ngIf="post.postTypeId == 6">
                            <img src="assets/images/emoji/confetti.png" alt="" class="img-fluid" style="width:16px">
                            <span class="ms-2" *ngIf="post.likes_count > 0"><small style="font-weight: 500;">{{ post.likes_count }}
                                    {{ 'Likes' | translate }}</small></span>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="likes-box">
                            <ng-container *ngFor="let user of likesList; let i = index">
                                <div class="row py-2 mx-0">
                                    <div class="col-auto">
                                        <div class="avatar">
                                            <img class="img-30 rounded-circle" [src]="user.liked_by.profileImageUrl ? user.liked_by.profileImageUrl : 'assets/images/avtar/user.png'" alt="#"
                                                style="object-fit: cover; aspect-ratio:1/1" />
                                        </div>
                                    </div>
                                    <!-- <div class="col px-xl-0">
                                        <div style="line-height: 12px;"><small>{{ user.liked_by.fullName }}</small></div>
                                    </div> -->
                                    <div class="col-10 col-md-11 col-xl-9 px-xl-0">
                                        <div class="">
                                            <div class="fw-semibold lh-1">{{ user.liked_by.fullName }}</div>
                                            <small>{{ getTimeAgo(user.addedDate) }}</small>
                                        </div>
                                        <!-- <div><small>{{ user.liked_by.fullName }}</small></div> -->
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <p class="text-center" style="color: #5272f2; cursor:pointer" (click)="fetchLikeList()" *ngIf="(likeCurrentPage < likeTotalPage) && !isLoading">Load more</p>
                        <div class="loader-box" *ngIf="isLoading">
                            <div class="loader-5"></div>
                          </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2" style="max-height: 80vh; overflow: auto"></div>
        </div>
    </div>
</div>