<div class="card preventive-card mb-2 h-100">
    <div class="card-header pt-2 pb-1 px-2">
        <div class="row">
            <div class="col d-flex">
                <div class="col-auto" style="z-index: 2;">
                    <div class="profile-box">
                        <img src="/assets/images/avtar/user-img.avif" alt="" width="100%" height="100%">
                    </div>
                </div>
                <div class="ms-2 col-auto" style="z-index: 2;">
                    <div class="user-name"><strong>{{ item.userName }}</strong></div>
                    <div class="d-flex"><small>{{ item.date }}</small></div>
                </div>
            </div>
        </div>
    </div>
    <div class=" card-body pt-2 pb-1 px-2">
        <div>
            <div class="img-box mb-2" *ngIf="item.img">
                <img [src]='item.img' alt="" width="100%" style="object-fit: contain;">
            </div>
        </div>
        <div><strong>Lorem ipsum dolor sit amet.</strong></div>
        <div class="text-secondary">
            <small class="post-text-content">Lorem ipsum dolor, sit amet consectetur adipisicing
                elit. Asperiores, ipsa laudantium?Excepturi, placeat nulla. Debitis animi earum
                blanditiis minima officia.</small>
        </div>
        <div class="row px-2">
            <div class="col-4 mb-2 px-1 images-box">
                <img src="/assets/images/avtar/user-img.avif" alt="" width="100%" class="images ratio ratio-1x1"
                    style="object-fit: contain;">
            </div>
            <div class="col-4 mb-2 px-1 images-box">
                <img src="/assets/images/avtar/user-img.avif" alt="" width="100%" class="images ratio ratio-1x1"
                    style="object-fit: contain;">
            </div>
            <div class="col-4 mb-2 px-1 images-box">
                <img src="/assets/images/avtar/user-img.avif" alt="" width="100%" class="images ratio ratio-1x1"
                    style="object-fit: contain;">
                <div class="img-counts-overlay d-flex justify-content-center align-items-center">
                    <span class="img-counts">3+</span>
                </div>
            </div>
        </div>
    </div>

    <div class="card-footer py-2 px-2 h-100 d-none d-md-block" *ngIf="!item.img">
        <div>
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li [ngbNavItem]="'like'" class="like-list-li">
                    <a ngbNavLink>
                        <div class="d-flex align-item-center">
                            <i class="fa fa-thumbs-up" style="font-size: 21px; color:#ff1e66;"></i>
                            <span class=""><small>25k Likes</small></span>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="likes-box">
                            <div class="row py-2 mx-0" *ngFor="let data of data_list">
                                <div class="col-auto">
                                    <div class="avatar">
                                        <img class="img-30 rounded-circle" [src]="data.img" alt="#"
                                            style="object-fit: cover; aspect-ratio:1/1" />
                                    </div>
                                </div>
                                <div class="col-10 col-md-11 col-xl-9 px-xl-0">
                                    <div><strong>{{ data.user_id }}</strong></div>
                                    <div style="line-height: 12px;"><small>{{ data.name }}</small></div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="'comment'" class="comments-list-li">
                    <a ngbNavLink>
                        <div class="d-flex align-item-center justify-content-end">
                            <svg style="color:#7366ff;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                fill="currentColor" class="bi bi-chat-text-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                            </svg><span class="ms-2"><small>1.2k Comments</small></span>
                        </div>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="comments-box">
                            <ng-container  *ngFor="let data of data_list;index as i">
                            <div class="row py-2 mx-0" *ngIf="i <= 2">
                                    <div class="col-auto">
                                        <div class="avatar">
                                            <img class="img-30 rounded-circle" [src]="data.img" alt="#"
                                                style="object-fit: cover; aspect-ratio:1/1" />
                                        </div>
                                    </div>
                                    <div class="col-10 col-md-11 col-xl-9 px-xl-0">
                                        <div><strong>{{ data.user_id }}</strong></div>
                                        <div style="line-height: 12px;">
                                            <small>{{ data.comments }}</small>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
    <div class="card-footer py-2 px-2 d-none d-md-block" *ngIf="item.img">
        <div class="d-flex justify-content-between">
            <div class="d-flex align-item-center">
                <i class="fa fa-thumbs-o-up" style="font-size: 20px; color:#ff1e66;" *ngIf="!postLike"></i>
                <i class="fa fa-thumbs-up" style="font-size: 21px; color:#ff1e66;" *ngIf="postLike"></i>
                <span class="ms-2"><small style="font-weight: 500;">25k Likes</small></span>
            </div>
            <div class="d-flex align-item-center">
                <svg style="color:#7366ff;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    fill="currentColor" class="bi bi-chat-text-fill" viewBox="0 0 16 16">
                    <path
                        d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                </svg><span class="ms-2"><small style="font-weight: 500;">1.2k Comments</small></span>
            </div>
        </div>
    </div>
    <div class="card-footer py-2 px-2 d-md-none">
        <div class="d-flex justify-content-between">
            <div class="d-flex align-item-center">
                <i class="fa fa-thumbs-o-up" style="font-size: 20px; color:#ff1e66;" *ngIf="!postLike"></i>
                <i class="fa fa-thumbs-up" style="font-size: 21px; color:#ff1e66;" *ngIf="postLike"></i>
                <span class="ms-2"><small style="font-weight: 500;">25k Likes</small></span>
            </div>
            <div class="d-flex align-item-center">
                <svg style="color:#7366ff;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    fill="currentColor" class="bi bi-chat-text-fill" viewBox="0 0 16 16">
                    <path
                        d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                </svg><span class="ms-2"><small style="font-weight: 500;">1.2k Comments</small></span>
            </div>
        </div>
    </div>
</div>