import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { PermissionService } from "./permission.service";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  action?: boolean;
  children?: Menu[];
  moduleKey?: string;
  actionKey?: string;
  default?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  public adminMenuCollapsed: boolean = false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(
    private router: Router,
    private permissionService: PermissionService
  ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  createSideMenu() {
    this.MENUITEMS.length = 0;
    this.ADMINMENU.length = 0;
    this.ALLMENUITEMS.forEach((item) => {
      if (item.default && item.default == true) {
        this.MENUITEMS.push(item);
      } else {
        if (item.moduleKey) {
          const hasPermission = this.permissionService.checkPermission(
            item.moduleKey,
            item.actionKey
          );
          if (hasPermission) {
            this.MENUITEMS.push(item); // change  ADMINMENU to MENUITEMS
          }
        } else {
          if (item.children) {
            const children = [];
            item.children.forEach((childItem) => {
              if (childItem.moduleKey) {
                const hasPermission = this.permissionService.checkPermission(
                  childItem.moduleKey,
                  childItem.actionKey
                );
                if (hasPermission) {
                  children.push(childItem);
                }
              } else {
                if (childItem.children) {
                  const ChildrenSubItem = [];
                  childItem.children.forEach((childSubItem) => {
                    const hasPermission =
                      this.permissionService.checkPermission(
                        childSubItem.moduleKey,
                        childSubItem.actionKey
                      );
                    if (hasPermission) {
                      ChildrenSubItem.push(childSubItem);
                    }
                  });
                  if (ChildrenSubItem.length > 0) {
                    Object.assign(childItem, { children: ChildrenSubItem });
                    children.push(childItem);
                  }
                }
              }
            });
            if (children.length > 0) {
              const itemArray = { ...item };
              Object.assign(itemArray, { children: children });
              this.MENUITEMS.push(itemArray);
            }
          }
        }
      }
    });
    this.ALLADMINMENU.forEach((item) => {
      if (item.default && item.default == true) {
        this.ADMINMENU.push(item);
      } else {
        if (item.moduleKey) {
          const hasPermission = this.permissionService.checkPermission(
            item.moduleKey,
            item.actionKey
          );
          if (hasPermission) {
            this.ADMINMENU.push(item);
          }
        } else if (item.children) {
          const children = [];
          item.children.forEach((childItem) => {
            if (childItem.moduleKey) {
              const hasPermission = this.permissionService.checkPermission(
                childItem.moduleKey,
                childItem.actionKey
              );
              if (hasPermission) {
                children.push(childItem);
              }
            } else {
              if (childItem.children) {
                const ChildrenSubItem = [];
                childItem.children.forEach((childSubItem) => {
                  const hasPermission = this.permissionService.checkPermission(
                    childSubItem.moduleKey,
                    childSubItem.actionKey
                  );
                  if (hasPermission) {
                    ChildrenSubItem.push(childSubItem);
                  }
                });
                if (ChildrenSubItem.length > 0) {
                  Object.assign(childItem, { children: ChildrenSubItem });
                  children.push(childItem);
                }
              }
            }
          });
          if (children.length > 0) {
            const itemArray = { ...item };
            Object.assign(itemArray, { children: children });
            this.ADMINMENU.push(itemArray);
          }
        }
      }
    });
    this.adminItems.next(this.ADMINMENU);
    this.items.next(this.MENUITEMS);
  }

  destroySideMenu() {
    this.ADMINMENU = [];
    this.MENUITEMS = [];
    this.adminMenuCollapsed = false;
    this.adminItems.next(this.ADMINMENU);
    this.items.next(this.MENUITEMS);
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.ADMINMENU = [];
    this.MENUITEMS = [];
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  ALLMENUITEMS: Menu[] = [
    {
      title: "Dashboard",
      icon: "home",
      type: "link",
      path: "/dashboard",
      active: true,
      default: true,
    },
    // {
    //   title: "Module",
    //   icon: "home",
    //   type: "link",
    //   path: "/module-management/modules",
    //   active: true,
    // },
    // {
    //   path: "/notifications",
    //   title: "Notifications",
    //   icon: "bookmark",
    //   type: "link",
    //   bookmark: true,
    // },
    {
      title: "Member",
      icon: "user",
      type: "link",
      path: "member",
      active: false,
      moduleKey: 'Member',
      actionKey: "MemberList",
    },
    {
      title: "Post",
      icon: "task",
      type: "sub",
      active: false,
      children: [
        // {
        //   title: "Member Post",
        //   type: "sub",
        //   active: false,
        //   children: [
        //     {
        //       path: "/member-post/pending-post",
        //       title: "Pending",
        //       type: "link",
        //       active: false,
        //       moduleKey: "MemberPost",
        //       actionKey: "MemberPostPendingList",
        //     },
        //     {
        //       path: "/member-post/approved-post",
        //       title: "Active/Inactive",
        //       type: "link",
        //       moduleKey: "MemberPost",
        //       actionKey: "MemberPostActiveList",
        //     },
        //     {
        //       path: "/member-post/cancelled-post",
        //       title: "Cancelled",
        //       type: "link",
        //       active: false,
        //       moduleKey: "MemberPost",
        //       actionKey: "MemberPostCancelledList",
        //     },
        //   ],
        // },
        {
          title: "Antim Yatra Post",
          type: "sub",
          active: false,
          children: [
            // {
            //   path: "/maran-post/antim-yatra/add",
            //   title: "Add Antim Yatra",
            //   type: "link",
            //   active: false,
            //   moduleKey: "AntimYatraPost",
            //   actionKey: "AntimYatraPostAdd",
            // },
            {
              path: "/maran-post/antim-yatra/add",
              title: "Add Antim Yatra",
              type: "link",
              active: false,
              moduleKey: "AntimYatraPost",
              actionKey: "AntimYatraPostAdd",
            },
            {
              path: "/maran-post/antim-yatra/pending-post",
              title: "Pending",
              type: "link",
              active: false,
              moduleKey: "AntimYatraPost",
              actionKey: "AntimYatraPostPendingList",
            },
            {
              path: "/maran-post/antim-yatra/approved-post",
              title: "Active/Inactive",
              type: "link",
              active: false,
              moduleKey: "AntimYatraPost",
              actionKey: "AntimYatraPostActiveList",
            },
            {
              path: "/maran-post/antim-yatra/cancelled-post",
              title: "Cancelled",
              type: "link",
              active: false,
              moduleKey: "AntimYatraPost",
              actionKey: "AntimYatraPostCancelledList",
            },
          ],
        },
        {
          title: "Prathna Post",
          type: "sub",
          active: false,
          children: [
            {
              path: "/maran-post/prayer/add",
              title: "Add Prathna",
              type: "link",
              active: false,
              moduleKey: "PrayerPost",
              actionKey: "PrayerPostAdd",
            },
            {
              path: "/maran-post/prayer/pending-post",
              title: "Pending",
              type: "link",
              active: false,
              moduleKey: "PrayerPost",
              actionKey: "PrayerPostPendingList",
            },
            {
              path: "/maran-post/prayer/approved-post",
              title: "Active/Inactive",
              type: "link",
              active: false,
              moduleKey: "PrayerPost",
              actionKey: "PrayerPostActiveList",
            },
            {
              path: "/maran-post/prayer/cancelled-post",
              title: "Cancelled",
              type: "link",
              active: false,
              moduleKey: "PrayerPost",
              actionKey: "PrayerPostCancelledList",
            },
          ],
        },
        {
          title: "Community Post",
          icon: "bookmark",
          type: "sub",
          active: false,
          children: [
            {
              path: "/community-post/add",
              title: "Add Community Post",
              type: "link",
              active: false,
              moduleKey: "CommunityPost",
              actionKey: "CommunityPostAdd",
            },
            {
              path: "/community-post/pending-post",
              title: "Pending",
              type: "link",
              active: false,
              moduleKey: "CommunityPost",
              actionKey: "CommunityPostPendingList",
            },
            {
              path: "/community-post/approved-post",
              title: "Active/Inactive",
              type: "link",
              active: false,
              moduleKey: "CommunityPost",
              actionKey: "CommunityPostActiveList",
            },
            {
              path: "/community-post/cancelled-post",
              title: "Cancelled",
              type: "link",
              active: false,
              moduleKey: "CommunityPost",
              actionKey: "CommunityPostCancelledList",
            },
          ],
        },
        {
          title: "Patrika",
          icon: "bookmark",
          type: "sub",
          active: false,
          children: [
            {
              path: "/post/patrika/add",
              title: "Add Patrika",
              type: "link",
              active: false,
              moduleKey: "PatrikaPost",
              actionKey: "PatrikaPostAdd",
            },
            {
              path: "/post/patrika/pending-post",
              title: "Pending",
              type: "link",
              active: false,
              moduleKey: "PatrikaPost",
              actionKey: "PatrikaPostPendingList",
            },
            {
              path: "/post/patrika/approved-post",
              title: "Active/Inactive",
              type: "link",
              active: false,
              moduleKey: "PatrikaPost",
              actionKey: "PatrikaPostActiveList",
            },
            {
              path: "/post/patrika/cancelled-post",
              title: "Cancelled",
              type: "link",
              active: false,
              moduleKey: "PatrikaPost",
              actionKey: "PatrikaPostCancelledList",
            },
          ],
        },
        {
          path: "/post/comments",
          title: "Comments",
          icon: "post",
          type: "link",
          bookmark: true,
          moduleKey: "Comments",
          actionKey: "CommentsList",
        },
      ],
    },
    // {
    //   title: " Verification",
    //   icon: "user",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     {
    //       path: "/verification/payment-approval",
    //       title: "Payment & Approval",
    //       type: "link",
    //     },
    //   ],
    // },
    // {
    //   path: "/feedback-management/feedback",
    //   title: "Feedback Management",
    //   icon: "chat",
    //   type: "link",
    //   bookmark: true,
    //   moduleKey: "Feedbacks",
    //   actionKey: "FeedbacksList",
    // },
    {
      title: "CMS",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        {
          path: "/cms/contact-management",
          title: "Contact Management",
          type: "link",
          moduleKey: "Contacts",
          actionKey: "ContactsList",
        },
        {
          path: "/cms/about-community",
          title: "About Community",
          type: "link",
          moduleKey: "Contacts",
          actionKey: "ContactsList",
        },
        {
          path: "/cms/sanstha",
          title: "Sanstha",
          type: "link",
          moduleKey: "sanstha",
          actionKey: "sansthaList",
        },
        {
          path: "/cms/emergency-contact-number",
          title: "Emergency Contact Number",
          type: "link",
          moduleKey: "EmergencyContact",
          actionKey: "EmergencyContactList",
        },
        {
          path: "/cms/community-team",
          title: "Community Team",
          type: "link",
          moduleKey: "CommunityTeam",
          actionKey: "CommunityTeamList",
        },
        {
          title: "Policies",
          type: "sub",
          children: [
            {
              path: "/policies/privacy-policy",
              title: "Privacy Policy",
              type: "link",
            },
            {
              path: "/policies/terms-conditions",
              title: "Terms & Conditions",
              type: "link",
            },
          ],
        },
        {
          path: "/cms/social-media",
          title: "Social Media",
          type: "link",
          moduleKey: "Faq",
          actionKey: "FaqList",
        },
        {
          path: "/cms/faq",
          title: "FAQ",
          type: "link",
          moduleKey: "Faq",
          actionKey: "FaqList",
        },
      ],
    },
    {
      path: "/feedback-management/feedback",
      title: "Feedback Management",
      icon: "chat",
      type: "link",
      // bookmark: true,
      moduleKey: "Feedbacks",
      actionKey: "FeedbacksList",
    },
    {
      title: "Admin",
      icon: "icons",
      type: "sub",
      action: true,
      default: true,
    },
  ];

  ALLADMINMENU: Menu[] = [
    // {
    //   title: "Admin Dashboard",
    //   icon: "home",
    //   type: "link",
    //   path: "/admin-dashboard",
    //   active: true,
    //   default: true
    // },
    {
      title: "Staff Management",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        {
          path: "/staff-management/roles",
          title: "Roles",
          type: "link",
          moduleKey: "Roles",
          actionKey: "RolesList",
        },
        {
          path: "/staff-management/users",
          title: "User",
          type: "link",
          moduleKey: "User",
          actionKey: "UserList",
        },
      ],
    },
    // {
    //   title: "Tag Group",
    //   icon: "project",
    //   path: "/tag-management/tag-group",
    //   type: "link",
    //   active: false,
    // children: [
    //   // { path: "/tag-management/tag-group", title: "Tag group", type: "link" },
    //   { path: "/tag-management/tags", title: "Tags", type: "link" },
    // ],
    // },

    // {
    //   icon: "social",
    //   path: "/post/setting",
    //   title: "Post Setting",
    //   type: "link",
    //   moduleKey: "PostSetting",
    //   actionKey: "PostSettingView",
    // },
    // {
    //   title: "UX Setting",
    //   icon: "project",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     {
    //       path: "/ux-setting/social-handles",
    //       title: "Social Handles",
    //       type: "link",
    //     },
    //     {
    //       path: "/ux-setting/logo-setup",
    //       title: "Logo Setup",
    //       type: "link",
    //     },
    //   ],
    // },

    // {
    //   title: " Reports",
    //   icon: "charts",
    //   type: "link",
    //   path: "/Reports",
    //   active: true,
    // },
    // {
    //   path: "/Global-setting",
    //   title: "Global Setting",
    //   icon: "user",
    //   type: "link",
    //   bookmark: true,
    // },
  ];

  ADMINMENU: Menu[] = [];

  MENUITEMS: Menu[] = [];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  adminItems = new BehaviorSubject<Menu[]>(this.ADMINMENU);
}
